/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        
        
      /*Nav Waypoint*/
      $('.nav_waypoint').waypoint(function(direction) {
        if (direction === 'down') {
               $('.navbar').addClass('nav_fixed');
        }
        else {
               $('.navbar').removeClass('nav_fixed');
        }
      });
      
      
      /*Nav Waypoint*/
      $('.sidebar_waypoint').waypoint(function(direction) {
        if (direction === 'down') {
               $('.sidebar_nav .side_list').addClass('sidebar_fixed');
        }
        else {
               $('.sidebar_nav .side_list').removeClass('sidebar_fixed');
        }
      });
      
      //Hide first link of 10ps subnav
      $('.10psnav ul.primary_list').prev('a').hide();
      
      
     
        //Tabs
        var number = $( ".tabs_layout_ul li" ).length;
        var totalheight = number * 53;
        $('.tabs_layout_content').css('height', totalheight);
        $('.tabs_layout_drop .fa-times').hide();
        $('#0').addClass('active');
        $('.tabs_layout_content').hide();
        $('#0box').show();
        $('.tabs_layout_drop').click(function(){
          $('.tabs_layout_drop .fa-bars').toggle();
          $('.tabs_layout_drop .fa-times').toggle();
          $('.tabs_layout_ul').toggle();
          $(this).toggleClass('tabs_layout_active');
        });
        
        
         //Tabs Switch
          $('.tabs_layout_ul li').each(function(){
            var itemid = $(this).attr("id");
            var itemidfinal = '#' + itemid + 'box';
            $(this).click(function(){
              $('.tabs_layout_content').hide();
              $(itemidfinal).show();
              
              $('.tabs_layout_ul li').removeClass('active');
              $(this).addClass('active');
          });
          }); 
        
        


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
         
          $(document).ready(function(){
          $(window).scroll(function(e){
            parallax();
          });
          function parallax(){
            var scrolled = $(window).scrollTop();
            $('.hero').css('top',(scrolled*0.5)+'px');
          }
          });
          
          
          $(document).ready(function(){
          $(window).scroll(function(f){
            parallax();
          });
          function parallax(){
            var scrolled = $(window).scrollTop();
            $('.hero-content h1').css('margin-top',(scrolled*-0.2)+'px');
          }
          });
          
        
        // Slider
        $('.flextest').flexslider({
         animation: "slide"
        });

      
        
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
